<script>
  import O from "./O.svelte";
  import X from "./X.svelte";
</script>

<div class="Logo">
  <O size={"small"} />
  <X size={"small"} />
</div>

<style lang="scss">
  .Logo {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 1rem 0;
  }
</style>
